import React from "react"
import Font42 from "components/Fonts/Font42"
import Font24 from "components/Fonts/Font24"
import { Grid, useMediaQuery, useTheme } from "@mui/material"
import ApplicationForm from "components/ApplicationForm/ApplicationForm"
import { StaticImage } from "gatsby-plugin-image"

const FormSection = ({ noBottom }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <section>
      <Grid
        my={12}
        mb={isMobile ? 5 : 12}
        container
        direction={"column"}
        alignItems={"center"}
      >
        <Font42>Melden Sie sich bei uns!</Font42>
        <Font24 fw={400} textAlign={isMobile ? "center" : "left"}>
          Füllen Sie das kurze Formular aus und wir rufen Sie zurück!
        </Font24>
      </Grid>
      <Grid item xs={12} mb={noBottom ? 0 : isMobile ? 10 : 20}>
        <Grid container wrap={isMobile ? "wrap" : "nowrap"}>
          <Grid item xs={isMobile ? 12 : 8} sx={{ zIndex: 1 }}>
            <ApplicationForm />
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 4}
            sx={{ ml: isMobile ? 0 : -2, mt: isMobile ? -2 : 0 }}
          >
            <StaticImage
              src="../../../images/application-form.png"
              width={520}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Formularz kontaktowy"
              placeholder="transparent"
              style={{ height: "100%", width: "100%" }}
            />
          </Grid>
        </Grid>
      </Grid>
    </section>
  )
}

export default FormSection
