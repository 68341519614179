import { Grid } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Font18 from "components/Fonts/Font18"
import { companyData } from "../../constants/companyData"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      mediraBig: file(name: { eq: "medira-logo-big" }) {
        childImageSharp {
          gatsbyImageData(
            backgroundColor: "transparent"
            quality: 95
            placeholder: NONE
          )
        }
      }
    }
  `)

  const image = data.mediraBig.childImageSharp.gatsbyImageData
  return (
    <Grid container mt={12} spacing={4}>
      <Grid
        item
        xs={12}
        md={7}
        container
        justifyContent={{ xs: "center", md: "start" }}
        alignItems={"center"}
      >
        <GatsbyImage image={image} alt="Logo Medira" />
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        container
        justifyContent={{ xs: "center", md: "start" }}
        flexDirection={"column"}
      >
        <Font18 fw={900} mb={0} textAlign={{ xs: "center", md: "left" }}>
          MEDIRA Pflege zu Hause
        </Font18>
         <a
          href={`tel:${companyData.telephone}`}
          style={{ textDecoration: "none" }}
        >
  <Font18 mb={0} textAlign={{ xs: "center", md: "left" }}>
          {companyData.telephoneFormatted}
        </Font18>

        </a>
      
        <Font18 mb={0} textAlign={{ xs: "center", md: "left" }}>
        {companyData.mail}
        </Font18>
        <Font18 mb={0} textAlign={{ xs: "center", md: "left" }}>
          Bürozeiten: Mo-Fr 8:00 - 16:00
        </Font18>
      </Grid>
    </Grid>
  )
}

export default Header
